<script>
    import {myfields, user_loged_in,myVRAmaps} from '../myfields_store.js'
    import { _ } from '../services/i18n';
    import { add } from "ol/coordinate";
    import { getContext } from 'svelte';
    import {sveltemap_global} from '../myfields_store.js'
    let api_host = 'https://tractorpilot.com'
     if (location.host.includes("localhost"))  api_host = 'http://tp.localhost'


     let files = [];
  const handleFileUpload = () => {

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      let ext = files[i]["name"].split(".").slice(-1).toString().toLowerCase();
      formData.append("files", files[i]); // Добавляем каждый файл в formData
    }

    fetch(api_host+"/Fields/saveKML/"+localStorage.getItem('client_code'), {
      method: "POST",
      body: formData
    })
    .then(response => response.json())
    .then(data => {
      if (data.err==0) {
        document.location="/#fields";
        location.reload();
      }  
      else {
          alert ("Upload Error "+data.err);
      }
      console.log("File uploaded successfully", data);
    })
    .catch(error => {
      console.error("Error uploading file:", error);
    });
    
  
};




</script>

<div class="container-fluid paper-wrap bevel tlbr">

    <!-- CONTENT -->
    <!--TITLE -->
    <div class="row">
        <div id="paper-top">
            <div class="col-sm-3">
                <h2 class="tittle-content-header">
                    <i class="icon-location"></i> 
                    <span>{$_("Load map from KML file")}
                    </span>
                </h2>

            </div>

    
         
        </div>
    </div>




       <div class="body-nest" id="validation">
        <div class="form_center">

            <form class="form-horizontal" on:submit|preventDefault={handleFileUpload}>
                <fieldset>
            

                    <div class="control-group">
                        <label class="control-label" for="name">{$_("Select .kml map file")}</label>
                        <div class="controls">
                        <input type="file"  
                        accept=".kml,.KML"
                        on:change={(e) => files = Array.from(e.target.files)}  />
                        </div>
                    </div>

           
                    <div class="form-actions" style="margin:20px 0 0 0;">
                        <button type="submit" class="btn btn-primary">{$_("Save")}</button>
                    </div>
                </fieldset>
            </form>

        </div>
    </div>



 
          
            
















</div>    